import React from 'react';

function Header() {
  return (
    <header>
      <video src={process.env.PUBLIC_URL + "/file_example_MP4_480_1_5MG.mp4"} loop autoPlay muted></video>
      <h1>Driving Innovation in Software, Finance, and Real Estate</h1>
      <div className="rowy">
        <button className="btny" style={{ cursor: "pointer" }}>
          Sign Up
        </button>

        <button className="btny" style={{ cursor: "pointer" }}>
          Explore
        </button>
      </div>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
