import Card from "./Card";
import React from 'react';

function Services() {
  return (
    <div className="containery services">
        <h2 id="services" className="main-title text-center">OUR SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="rowy">
                    <div className="col-md-4 mb-2">
                    <Card title="Finance Services" img="card2.png" text="We are committed to offering the best money lending facilities on the market and as one of the leading Money Lending institutions in the country, we ensure that whenever you are in need of a quick emergency loan, we design and offer you the best product at the cheapest possible rates, without unnecessary  hustle or red tape." />
                    </div>
                    <div className="col-md-4 mb-2">
                        <Card title="Software Engineering" img="card1.png" text="Our team of experienced software engineers and developers work with the latest technologies and tools to deliver high-quality, reliable and efficient software solutions that help businesses to achieve their goals. Our services include software development, testing, deployment, and maintenance, and we have experience working in a variety of industries such as healthcare, finance, retail, and more. We pride ourselves on delivering solutions that are scalable, secure, and easy to use, and our team is committed to delivering projects on time and within budget." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Real Estate" img="card3.png" text="We're a small team of developers, designers, and brokers who have felt the pain of struggling to find a new home. We live in Kampala and so have limited the application to areas around Kampala for now. As we grow and make more networks, we'll add new areas and locations." />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
