/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from "react";
import { Row, Col } from "react-bootstrap";

const whoWeAreText = `We’re a small team of developers, designers, and brokers who provide comprehensive software engineering, finance, and real estate services.
Our software engineering services include custom software development, mobile app development, and web application development to help businesses streamline their 
operations and increase productivity. Our finance services include business loans, personal and salary loans and logbook finance loans to help individuals and 
businesses make informed financial decisions. Our real estate services include property search and acquisition, property management and leasing, and real estate 
investment advice to help clients find and manage properties that meet their investment goals.`;

const whyWeExistText = `We strive to deliver high-quality and cost-effective solutions that meet our clients’ unique needs and exceed their expectations.`;

const ChooseUsSectionDescription = ({ text }) => {
  return <span css={chooseUsCss.chooseUsSectionDescription}>{text}</span>;
};

function About() {
  return (
    <>
      <h2 id="about" className="main-title text-center about-h2 about-scroll">ABOUT US</h2>
      <Row css={chooseUsCss.self}>
        <Col css={chooseUsCss.whoWeAre}>
          <h2 css={chooseUsCss.chooseUsSectionTitle}>Who we are?</h2>
          <ChooseUsSectionDescription text={whoWeAreText} />
        </Col>
        <Col css={chooseUsCss.whyWeExist}>
          <h2 css={chooseUsCss.chooseUsSectionTitle}>Why we exist?</h2>
          <ChooseUsSectionDescription text={whyWeExistText} />
        </Col>
      </Row>
    </>
  );
}

const chooseUsCss = {
  self: css({
    minHeight: "37.875rem",
    margin: "0 0 3.3125rem",
    padding: "5rem 6rem 5rem 5rem",
    backgroundColor: "#095352",
    "@media only screen and (max-width: 600px)": {
      padding: "0px",
      display: "block",
    },
  }),
  whoWeAre: css({
    padding: "2rem 5.375rem 4rem 4rem",
  }),
  whyWeExist: css({
    padding: "2rem 5.375rem 4rem 4rem",
    opacity: 0.9,
    borderRadius: "5px",
    backgroundColor: "#398d8c",
  }),
  chooseUsSectionTitle: css({
    margin: "2rem 0 1.5rem 0",
    fontFamily: "Roboto",
    fontSize: "2.5rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.4,
    letterSpacing: "normal",
    color: "#ffffff",
  }),
  chooseUsSectionDescription: css({
    color: "#ffffff",
    fontSize: "1.1rem",
  }),
};
export default About;
